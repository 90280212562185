<template lang="pug">
div.controls-wrapper
  v-icon.icon(icon="PlayCircleIcon" size="24" @click="goPrevPage")
  div.counter
    span.page {{ currentPage }}
    span /
    span.page {{ totalPages }}
  v-icon.icon(icon="PlayCircleIcon" size="24" @click="goNextPage")
</template>

<script>
export default {
  name: 'VPresentationControls',
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
  },
  data: () => ({}),
  computed: {},
  mounted() {
    document.addEventListener('keydown', this.keyDownListener)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.keyDownListener)
  },
  methods: {
    goPrevPage() {
      this.$emit('go-prev-page')
    },
    goNextPage() {
      this.$emit('go-next-page')
    },
    keyDownListener(key) {
      switch (key.code) {
        case 'ArrowRight':
        case 'PageUp':
          this.goNextPage()
          break
        case 'ArrowLeft':
        case 'PageDown':
          this.goPrevPage()
          break
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.controls-wrapper {
  display: flex;
  flex-direction: row;
  gap: 4px;
  padding: 10px;
  align-items: center;
  justify-content: center;
  background: white;
  border: 1px solid #b9b9c3;
  border-radius: $border-radius;

  .icon {
    &:first-child {
      transform: scale(-1, 1);
    }
  }

  .counter {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: #6e6b7b;

    .page {
      display: inline-block;
      width: 16px;
      text-align: center;
      padding-right: 3px;

      &:last-child {
        padding-right: 0;
        padding-left: 3px;
      }
    }
  }
}
</style>
